import React from "react";
import '../../estilos/Footer.css'

const Downbar = () => (
        <div>
          <div className="phantom" >
            <div className="estilo">
            JRC SIF México &copy; Copyright{" "} {new Date().getFullYear()}
            </div>
          </div>
        </div>
    )



export default Downbar;
