import React from 'react'

import CarouselInicio from './Carousel';
import CardsInicio from './Contenido';

const Home = () => (
  <content className="App-content">
    <CarouselInicio />
    <CardsInicio />
  </content>
)


export default Home
